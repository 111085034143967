<template>
  <v-dialog v-model="innerValue" transition="slide-y-transition" max-width="680">
    <v-card v-if="workplan && shift">
      <v-card-title class="grey lighten-4">
        <app-id-icon />
        <span class="body-1">
          {{ $t("time.calendar.shift_detail") }}
        </span>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="tertiary" icon @click="innerValue = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("buttons.close") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-subheader style="height: auto!important;">
                <v-divider class="ml-0" inset style="margin-right: 25px!important;" />
                <div class="d-flex flex-column align-center">
                  <div class="headline text-xs-center">{{ $helpers.formatDate(workplan.date) }}</div>
                  <span class="title text-center">{{ shift.name }}</span>
                </div>
                <v-divider inset style="margin-left: 25px!important;" />
              </v-subheader>
            </v-col>
            <v-col cols="12" v-if="workplan.countAsOvertime">
              <div class="body-1 text-center">
                {{ $t("time.calendar.employee_overtime") }}
              </div>
            </v-col>
            <v-col cols="12" v-if="shift.shiftType === 'OFF_DAY' && !workplan.countAsOvertime && !workplan.breakDay">
              <div class="body-1 text-center">
                {{ $t("time.calendar.employee_off_day") }}
              </div>
            </v-col>
            <v-col cols="12" v-if="shift.shiftType === 'OFF_DAY' && workplan.breakDay">
              <div class="body-1 text-center">
                {{ $t("time.calendar.employee_break_day") }}
              </div>
            </v-col>
            <v-col cols="12" v-if="workplan.leaveRequest">
              <v-list two-line class="pa-0">
                <v-list-item>
                  <v-list-item-avatar :color="workplan.leaveRequest.leaveType.color">
                    <v-icon size="24" color="white">{{ workplan.leaveRequest.leaveType.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.leave_type") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ workplan.leaveRequest.leaveType.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-col>
            <template v-if="shift.shiftType !== 'OFF_DAY'">
              <v-col cols="12">
                <v-divider class="mt-3"></v-divider>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col sm="6">
                    <v-list two-line class="pa-0">
                      <v-list-item v-if="shift.shiftType !== 'FLEXIBLE'">
                        <v-list-item-avatar>
                          <v-icon size="35" color="teal" left>tw-in</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("time.calendar.begin_time") }}</v-list-item-title>
                          <v-list-item-subtitle>{{ shift.beginTime }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon size="30" color="brown" left>mdi-coffee</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("time.calendar.break_time") }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ $helpers.convertMinutesToTime(shift.totalBreakTimeInMinutes) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col sm="6">
                    <v-list two-line class="pa-0">
                      <v-list-item v-if="shift.shiftType !== 'FLEXIBLE'">
                        <v-list-item-avatar>
                          <v-icon size="35" color="red" left>tw-out</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("time.calendar.end_time") }}</v-list-item-title>
                          <v-list-item-subtitle>{{ shift.endTime }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon size="30" color="blue" left>mdi-update</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("time.calendar.shift_time") }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            $helpers.convertMinutesToTime(shift.totalWorkingTimeInMinutes)
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <template v-if="workplan.overtimes.length > 0">
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col sm="6">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" color="red" left>tw-access-pass-plan</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("shift.overtime") }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ workplan.overtimes[0].overtimeInMinutes }} {{ $t("date.minute") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col sm="6">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" color="red" left>tw-access-pass-plan</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(`time.calendar.overtime_request.periods.${workplan.overtimes[0].period}`) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t(`leave_request_status.${workplan.overtimes[0].requestStatus}`) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </template>
            <v-col cols="12" v-if="shift.latelyClockInTolerance && shift.earlyClockOutTolerance">
              <v-divider />
            </v-col>
            <v-col sm="6" v-if="shift.maxClockInOvertimeInMinutes">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon size="30" left>mdi-clock-end</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("time.calendar.max_clock_in_overtime_in_minutes") }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ shift.maxClockInOvertimeInMinutes }} {{ $t("date.minute") }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col sm="6" v-if="shift.maxClockOutOvertimeInMinutes">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon size="30" left>mdi-clock-start</v-icon>
                  </v-list-item-avatar>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.max_clock_out_overtime_in_minutes") }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ shift.maxClockOutOvertimeInMinutes }} {{ $t("date.minute") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col sm="6" v-if="shift.latelyClockInTolerance">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon size="30" left>mdi-clock-in</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("time.calendar.lately_clock_in_tolerance") }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ shift.latelyClockInTolerance }} {{ $t("date.minute") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col sm="6" v-if="shift.latelyClockInTolerance">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon size="30" left>mdi-clock-out</v-icon>
                  </v-list-item-avatar>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.early_clock_out_tolerance") }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ shift.earlyClockOutTolerance }} {{ $t("date.minute") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ShiftDetailDialog",
    props: {
      value: Boolean,
      workplan: Object,
      shift: Object
    },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>
